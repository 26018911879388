import { Button, Card } from "flowbite-react";
import {
  FaCode,
  FaConnectdevelop,
  FaCompass,
  FaAnglesUp,
  FaGears,
  FaHeadset,
} from "react-icons/fa6";

import SLDLogo from "../assets/sld-logo.jpg";
import SQRCLogo from "../assets/sqrc-logo.jpg";
import ApployLogo from "../assets/apploy-logo.png";
import AddresslyLogo from "../assets/addressly-logo.png";
export interface OfferingsCardProps {
  title: string;
  description: string;
  icon: keyof typeof iconMap;
  ctaLink?: string;
}
const iconMap = {
  code: <FaCode className="mx-auto" size={70} />,
  develop: <FaConnectdevelop className="mx-auto" size={70} />,
  compass: <FaCompass className="mx-auto" size={70} />,
  anglesUp: <FaAnglesUp className="mx-auto" size={70} />,
  gears: <FaGears className="mx-auto" size={70} />,
  headset: <FaHeadset className="mx-auto" size={70} />,
  sld: SLDLogo,
  sqrc: SQRCLogo,
  apploy: ApployLogo,
  addressly: AddresslyLogo,
};
export const OfferingsCard = ({
  title,
  description,
  icon,
  ctaLink,
}: OfferingsCardProps) => {
  const attributes = {} as any;

  return (
    <Card className="text-center" {...attributes}>
      {typeof iconMap[icon] === "string" ? (
        <img
          src={iconMap[icon] as string}
          alt={`${title} Logo`}
          width={70}
          className="mx-auto rounded"
        />
      ) : (
        iconMap[icon]
      )}
      <h5 className="text-2xl font-bold text-black">{title}</h5>
      <p className="font-normal">{description}</p>
      {ctaLink && (
        <Button fullSized color="primary" href={ctaLink} target="_blank">
          View on Shopify
        </Button>
      )}
    </Card>
  );
};
